import firebase from 'firebase';
import { UserStore } from '../src/stores';
import axios from 'axios';
import { Transducer } from '../src/utils/transducer';

const env = process.env.NODE_ENV || 'development';
const dev = env === 'development';

let config = {
  apiKey: 'AIzaSyDM5HrPL4RcegKWSwrhB8InkphDvhIJEG4',
  authDomain: 'sigaro-dev.firebaseapp.com',
  databaseURL: 'https://sigaro-dev.firebaseio.com',
  projectId: 'sigaro-dev',
  storageBucket: 'sigaro-dev.appspot.com',
  messagingSenderId: '924353704958',
  appId: '1:924353704958:web:857cbace205f3264',
};

if (dev) {
  console.log('Using dev settings');
} else {
  console.log('Using prod settings');
  config = {
    apiKey: 'AIzaSyD23oTCFdNoSGDkVwmUkxQYHTL_TauIAEM', // FIXME Need to restrict this key at https://console.developers.google.com/apis/credentials?project=sigaro-app
    authDomain: 'auth.boxpressd.com',
    databaseURL: 'https://sigaro-app.firebaseio.com',
    projectId: 'sigaro-app',
    storageBucket: 'sigaro-app.appspot.com',
    messagingSenderId: '506026949744',
    appId: '1:506026949744:web:8993e649a884f845',
  };
}

const firebaseApp = firebase.initializeApp(config);

firebaseApp.auth().onAuthStateChanged((user) => {
  console.log('Firebase auth updated');
  if (user) {
    UserStore.update((s) => { s.firebaseUser = user; });
  }
});

// FIXME For some reason, this doesn't update when it sits idle
// Check out https://stackoverflow.com/questions/60020210/react-js-firebase-authentication-is-not-working-properly
firebaseApp.auth().onIdTokenChanged((user) => {
  console.log('Token updated');
  if (user) {
    user.getIdToken().then((tokenId) => {
      console.log('Storing token...');
      if (tokenId) {
        Transducer.setPreference('boxpressd_token_id', tokenId);
        axios.defaults.headers.common.Authorization = `Bearer ${tokenId}`;
        UserStore.update((s) => {
          s.token = tokenId;
        });
      }
    });
  }
});

export default firebaseApp;
export const firestore = firebaseApp.firestore();
