import { language, messages } from '../src/utils/localeUtils';

export default {
  save: messages[language]?.save || 'Save',
  unsave: messages[language]?.unsave || 'Unsave',
  favorite: messages[language]?.favorite || 'Favorite',
  unfavorite: messages[language]?.unfavorite || 'Unfavorite',
  share: messages[language]?.share || 'Share',
  add_to_humidor: messages[language]?.add_to_humidor || 'Add to Humidor',
  report_incorrect_data: messages[language]?.report_incorrect_data || 'Report Incorrect Data',
  private_profile: 'This user\'s profile is private',
};
