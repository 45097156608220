export default {
  avatar: 'https://cdn.boxpressd.io/placeholder/default_avatar.png',
  user: 'https://cdn.boxpressd.io/placeholder/default_avatar.png',
  cigar: 'https://cdn.boxpressd.io/placeholder/no-cigar.jpg',
  band: 'https://cigarsnearme-cdn.s3.amazonaws.com/web/store-grayscale.jpg',
  venue: 'https://cigarsnearme-cdn.s3.amazonaws.com/web/store-default.jpg',
  drink: 'https://cdn.boxpressd.io/placeholder/no_drink.png',
  yelpStars: 'https://cdn.boxpressd.io/placeholder/yelp_stars_default.png',
  covers: {
    profile: 'https://cdn.boxpressd.io/placeholder/profile_bg.jpg',
    venue: 'https://cdn.boxpressd.io/placeholder/venue_bg.jpg',
    humidor: 'https://cdn.boxpressd.io/placeholder/default_humidor.jpg',
  },
  location: {
    none: 'https://cdn.boxpressd.io/placeholder/s_logo.png',
    marker: 'https://cigarsnearme-cdn.s3.amazonaws.com/web/map-marker-42.png',
    current: 'https://cdn.boxpressd.io/assets/img/current_location.png',
  },
  shipping: {
    markers: {
      ups: 'https://cdn.boxpressd.io/assets/img/map-marker-ups-42.png',
      usps: 'https://cdn.boxpressd.io/assets/img/map-marker-usps-42.png',
      fedex: 'https://cdn.boxpressd.io/assets/img/map-marker-fedex-42.png',
    },
  },
};
